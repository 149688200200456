import appStore from "./app_store_badge_US-UK_blk.svg";
import googlePlay from "./google-play-badge-new.png";
import gather_by_town from "./close_up_welcome_web.png";

import EmailForm from "./EmailForm";

import "./App.css";

function App() {
  return (
    <div className="App">
      <h1 className="title">mingle</h1>
      <img src={gather_by_town} className="Screenshot" alt="logo" />
      <p>coming soon</p>
      <EmailForm />
      <br />
      <img src={appStore} className="Apple-badge-logo" alt="logo" />
      <img src={googlePlay} className="Google-badge-logo" alt="logo" />
      <a
        className="App-link"
        href="https://www.iubenda.com/privacy-policy/67904512/full-legal"
        target="_blank"
        rel="noopener noreferrer"
      >
        <br />
        <p className="small">Privacy Policy</p>
      </a>
    </div>
  );
}

export default App;
