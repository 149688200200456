import firebase from "firebase";
import firebaseAdmin from "firebase/app";

var firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBTzLqwsmrmsKY3f6LvwhxCsLfaSj7Ygbs",
  authDomain: "nimble-net-295321.firebaseapp.com",
  databaseURL: "https://nimble-net-295321.firebaseio.com",
  projectId: "nimble-net-295321",
  storageBucket: "nimble-net-295321.appspot.com",
  messagingSenderId: "470801770850",
  appId: "1:470801770850:web:beb9552bec38fb77dae43b",
  measurementId: "G-1MBENKF1FE",
});

var db = firebaseApp.firestore();

export { db };
export { firebaseAdmin };
