import React from "react";
import "./App.css";
import { db, firebaseAdmin } from "./firebase";
import { withAlert } from "react-alert";

class EmailForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      input: {},

      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    let input = this.state.input;

    input[event.target.name] = event.target.value;

    this.setState({
      input,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      console.log(this.state);

      let input = {};

      input["email"] = "";

      this.setState({ input: input });
      const webContacts = db.collection("contacts").doc("web");

      webContacts
        .update({
          emails: firebaseAdmin.firestore.FieldValue.arrayUnion(
            this.state.input.email
          ),
        })
        .then(() => {
          this.props.alert.success("email added");
        })
        .catch((error) => {
          this.props.alert.show(error.message);
        });
    }
  }

  validate() {
    let input = this.state.input;

    let errors = {};

    let isValid = true;

    if (!input["email"]) {
      isValid = false;

      errors["email"] = "Please enter your email";
    }

    if (typeof input["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(input["email"])) {
        isValid = false;

        errors["email"] = "Please enter valid email";
      }
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div class="form-group">
            <input
              type="text"
              name="email"
              value={this.state.input.email}
              onChange={this.handleChange}
              className="field"
              placeholder=" email to join pre-launch..."
              id="email"
            />
            <input type="submit" value="send" className="button" />
            <div className="text-danger">{this.state.errors.email}</div>
          </div>
        </form>
      </div>
    );
  }
}

export default withAlert()(EmailForm);
